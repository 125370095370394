.redemption-modal__container {
  padding: 32px 24px;
}

.redemption-modal__title {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 1em;
}

.redemption-input__container {
  align-items: center;
  margin: 1em 0;
}

.redemption-input__label {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.redemption-input__divider {
  margin: 1em 0;
  border-bottom: 1px solid #c4c4c4;
}

.redemption-input__status {
  padding: 0.25em 2em;
  border: 1px solid transparent;
  border-radius: 28px;
  font-weight: 500;
}
