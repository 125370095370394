$font-awesome: FontAwesome;
$white: #ffffff;
$black: #000000;
$dark-font: #222222;
$grey: #777777;


.slick-dots {
  bottom: 0;
}

.slick-dots li button:before {
  font-size: 10px;
}

.qbee-home-slider,
.center-slider {
  .slick-prev,
  .slick-next {
    transform: scale(2.5);
    margin-top: -10px;
    z-index: 100;

    &:before {
      font: normal normal normal 10px/1 $font-awesome;
      color: #4c4c4c;
      background-color: white;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.1);
      border-radius: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .slick-next {
    right: 20px;

    &:before {
      content: "\f105";
    }
  }

  .slick-prev {
    left: 20px;

    &:before {
      content: "\f104";
    }
  }

  .home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 60vh;
    width: 75vw;

    @for $i from 1 through 56 {
      &.home#{$i} {
        background-image: url("https://www.paragon-innovation.com/static/media/main_image_2.3fe37426.png");
      }
    }
  }

  .slider-details {
    position: absolute;
    background-color: rgba($black, 0.1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 26%;
    right: 18%;
    text-align: center;
    padding: 55px;
    width: 510px;
    height: 510px;

    &.blue-slide {
      background-color: #223b7d;
    }

    .btn-white {
      margin-top: 15px;
    }

    h1 {
      color: #ffffff;
      font-weight: 700;
    }

    h3 {
      color: $white;
      position: relative;
      text-transform: uppercase;
      display: inline-block;

      &:after,
      &:before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: white;
        border-radius: 100%;
        top: 8px;
      }

      &:after {
        left: -22px;
        top: 8px;
      }

      &:before {
        top: 8px;
        right: -22px;
      }
    }

    h2 {
      font-weight: 400;
      margin-bottom: 0;
      font-size: 45px;
    }

    h4 {
      font-size: 24px;
    }

    h2,
    h3,
    h4 {
      color: white;
    }
  }

  .slider-contain {
    width: 100%;
    height: 75vh;
    display: flex;
    align-items: center;

    h1 {
      margin-bottom: 0;
      margin-top: 10px;
      color: $dark-font;
    }

    h4 {
      font-weight: 700;
      letter-spacing: 0.4em;
      color: $grey;
      margin-bottom: 0;
    }

    .btn-solid,
    .btn-outline {
      margin-top: 20px;
    }
  }

  .p-left {
    .slider-contain {
      justify-content: flex-start;
    }
  }

  .p-right {
    .slider-contain {
      justify-content: flex-end;
    }
  }

  .p-center {
    .slider-contain {
      justify-content: center;
    }
  }
}

.qbee-home-slider {
  &.text-white {
    .slider-contain {
      h1,
      h4 {
        color: $white;
      }
    }
  }
}


.qbee-home-slider {
  &.fullpage {
    .home {
      height: calc(100vh - 2px);

      .slider-contain {
        height: calc(100vh - 2px);
        padding-left: 25px;

        h1 {
          font-style: italic;
          font-size: 50px;
          margin-top: -12px;
        }

        p {
          max-width: 600px;
          line-height: 1.5;
          margin-top: 15px;
          margin-bottom: 10px;
          font-size: 16px;
        }
      }
    }

    .slick-next,
    .slick-prev {
      display: none !important;
    }

    &:hover {
      .slick-next,
      .slick-prev {
        display: none !important;
      }
    }

    .slick-dots {
      top: 50%;
      bottom: unset;
      width: unset;
      left: 20px;
      transform: translateY(-50%);

      li {
        display: flex;

        button {
          &:before {
            font-size: 15px;
            color: var(--theme-deafult);
            opacity: 0.2;
          }
        }
      }

      li {
        &.slick-active {
          button {
            &:before {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  &.instagram-slider {
    .home {
      height: 50vh;

      .slider-contain {
        height: 50vh;
      }
    }
  }

  &.slick-dotted {
    &.slick-slider {
      margin-bottom: 0;
    }
  }
}

.p-0 {
  .qbee-home-slider {
    background-size: cover;
    background-position: center center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
