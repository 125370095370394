.modal-campaign__container {
  padding: 10px 14px;
}

.modal-campaign__title {
  font-size: 18px;
  font-weight: 600;
}

.modal-campaign__info-wrapper {
  align-items: center;
  margin-bottom: 1em;
}

.modal-campaign__info-title {
  font-size: 16px;
  font-weight: 500;
}
