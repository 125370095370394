.add-package__container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  letter-spacing: 0;

  .selected-product__container {
    position: sticky;
    bottom: 32px;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 12;
    background-color: #fafafa;
    padding: 1em;
    border-radius: 4px;
    border: 1px solid #eaeaea;

    .selected-product {
      width: 100%;
      background: #ff8084;
      padding: 16px;
      border-radius: 8px;
    }

    .total-text,
    .product-text,
    .lihat-text {
      color: white;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
      letter-spacing: 0;
    }

    .product-text,
    .lihat-text {
      margin: 0;
    }
  }

  .product-selected__container-float {
    background: white;
    border: 1px solid #eaeaea;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: 0.3s;
    z-index: 12;
    position: relative;

    .products-container {
      margin-top: 24px !important;
      padding: 0 16px 32px !important;
      min-height: -webkit-fill-available;

      .product {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 16px;
        color: #1a1a1a;
        letter-spacing: 0;
        cursor: pointer;
        padding-right: 16px;
      }
    }
  }

  .button-submit {
    position: sticky;
    bottom: 16px;
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
  }

  .selected-product__header {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: #ff8084;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 12;
    position: relative;
    padding: 16px 24px;

    .selected-product__text {
      color: white;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
      letter-spacing: 0;
    }
  }

  .overlay {
    position: fixed;
    inset: 0;
    background: black;
    opacity: 0.2;
  }

  .main {
    width: 50vw;
    min-width: 580px;
    background: white;
    border-radius: 8px;
    position: relative;
    height: 90vh;
    overflow-y: auto;
  }

  .choose-product__container {
    position: sticky;
    top: 67px;
    padding-top: 16px;
    padding-bottom: 16px;
    background: white;
  }

  .main,
  .product-selected__container-float {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }

  .main::-webkit-scrollbar,
  .product-selected__container-float::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .footer {
    width: 100%;
    height: 32px;
    background: white;
    position: sticky;
    bottom: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 32px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 12;

    .title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
    }
  }

  .body {
    padding: 0 32px 32px;
    height: 100%;

    .label {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 12px;
      color: #1a1a1a;
      letter-spacing: 0;
    }

    .input-container {
      margin-bottom: 20px;

      .input {
        font-size: 14px;
        line-height: 20px;
        color: #4c4c4c;
      }
    }

    .products-container {
      padding-left: 8px;
      padding-bottom: 64px;
      min-height: -webkit-fill-available;
      overflow-y: auto;

      .product {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 16px;
        color: #1a1a1a;
        letter-spacing: 0;
        cursor: pointer;
      }
    }

    .package-type__container {
      display: flex;

      .package-type__item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        width: 120px;
        border: 1px solid #ff8084;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 32px;
      }

      .active {
        color: white;
        background: #ff8084;
      }

      .left {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      }

      .right {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
}
