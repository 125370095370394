.help-center__feedback-datepicker-container {
  align-items: center;
}

.help-center__feedback-datepicker-container .react-datepicker-wrapper {
  width: 150px;
  margin: 0 8px;
  cursor: pointer;
}

.help-center__feedback-datepicker-container .react-datepicker-wrapper input {
  width: 100%;
  text-align: center;
  height: 40px;
}

.help-center__feedback-datepicker-text {
  font-size: 16px;
  color: black;
  letter-spacing: 0;
  width: max-content;
  margin-right: 8px;
}

.help-center__feedback-table-container tr {
  background: white;
}

.help-center__feedback-table-container th {
  background: #ff8084;
  color: white;
}
