@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.payout-container {
  padding: 2cm !important;
  max-width: none !important;
}

.payout-head-title {
  color: #000;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  margin-bottom: 1em;
}

.logo {
  display: flex;
  justify-content: flex-end;
}

.logo img {
  width: 50%;
}

.payout-text {
  color: #000;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  margin-bottom: 14px;
}

.payout-bank-wrapper {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 14px;
  border: 1px solid #c4c4c4;
  padding: 12px;
  border-radius: 4px;
}

.payout-text-bank {
  color: #000;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-bottom: 0.25em;
}

.payout-th {
  color: #000 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  padding: 6px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
}

.payout-th.payout-item {
  width: 300px !important;
}

.payout-td {
  color: #000;
  font-size: 14px;
  padding: 8px !important;
  font-family: "Inter", sans-serif;
}

.payout-td.numeric {
  text-align: right !important;
  padding-right: 8px !important;
  margin-right: 0px !important;
  width: 130px;
}

.payout-restrict-id-wrapper {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 14px;
}
