.list-commission__container {
  padding: 32px 24px;
  background-color: #fffffd;
}

.list-commission__title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
}

.list-commission__total-payout-container {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 21px;
  padding: 32px 24px;
  width: 30%;
}

.list-commission__title-total-payout {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.list-commission__subtitle-total-payout {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
  margin: 1rem 0;
}

.list-commission__content-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  margin-top: 32px;
  padding: 20px;
}

.list-commission__container-filter {
  padding: 16px 12px;
}

.list-commission__table-container tr {
  background: white;
}

.list-commission__table-container th {
  background: #ff8084;
  color: white;
}

.empty-state {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: center;
  margin: 1.5rem 0;
}
