.articles-landing-page__container {
  .card-articles {
    padding: 30px;
    background: white;
    margin-bottom: 30px;
    border: 0px;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 8px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }

  .desktop-img {
    aspect-ratio: 9/16;
  }

  .mobile-img {
    aspect-ratio: 1/1;
    height: fit-content;
  }

  .card-articles {
    .header {
      padding-bottom: 20px;
      border-bottom: 2px solid #efefef;
      margin-bottom: 32px;
    }
  }

  .card-article {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .label {
      font-size: 16px;
      font-weight: 600;
      color: black;
      letter-spacing: 0;
      margin-bottom: 8px;
    }
  }
}

.modal-article__container {
  padding: 16px;
  height: max-content;

  .label-modal__input {
    font-size: 14px;
    font-weight: 500;
    color: black;
    letter-spacing: 0;
    margin-bottom: 8px;
  }
}
