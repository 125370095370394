.title {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.card-bottom {
  border-top: 1px solid #afafaf;
  margin-top: 2em;
}

.action-header {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.action-content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 1rem 0;
}

.action-status {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
