.create-warehouse__container {
  width: 100%;
  padding-bottom: 32px;

  .MuiAutocomplete-inputRoot {
    border-radius: 8px;
  }

  .create-warehouse__header {
    width: 100%;
    padding: 32px 16px;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 36px;
    }
  }

  .create-warehouse__body {
    background: white;
    padding: 16px;
    border-radius: 8px;

    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 48px;
    }

    .field-form {
      width: 100%;
      margin-bottom: 16px;
    }

    .label-form {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin: 16px 0;
      color: black;
      min-width: 100%;
    }

    .input-form {
      height: 48px;
      font-size: 18px;
      margin-bottom: 4px;
      border: 1px solid #cdcaca !important;
    }

    .text-area {
      border: 1px solid #cdcaca !important;
    }
    .text-area:hover {
      border: 1px solid black;
    }

    .input-form:hover {
      border: 1px solid black;
    }

    .button-form {
      margin-top: 32px;
    }
  }
}
