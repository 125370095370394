.add-article__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.add-article__wrapper {
  margin-bottom: 2em;
}
