.help-center__article-container {
  padding: 32px 24px;
}

.help-center__preview-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
}

.help-center__acticle-title {
  font-size: 24px;
  font-weight: 600;
}

.help-center__article-button {
  background: #ff8084 !important;
  color: white;
  height: 36px;
}

.help-center__article-table-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  padding: 20px;
}

.help-center__article-label {
  width: 180px;
  font-size: 16px;
  color: #1a1a1a;
}

.help-center__article-table-container tr {
  background: white;
}

.help-center__article-table-container th {
  background: #ff8084;
  color: white;
}
