.image-uploaded-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.image-uploaded,
.video {
  width: 64px;
  height: 64px;
  margin-right: 1rem;
  object-fit: cover;
  cursor: pointer;
}

.overlay-wrapper {
  top: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.overlay {
  width: 64px;
  height: 64px;
  background-color: rgba(26, 26, 26, 0.3);
}

.play-icon {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
