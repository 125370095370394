.modal-title {
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  padding-left: 1em;
  padding-top: 0.5em;
  margin-bottom: 0.5em;
}

.modal-label {
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  padding-left: 2em;
}

.modal-save__button {
  background: #ff8084 !important;
  color: white;
  width: 100%;
}

.modal-cancel__button {
  background: #c4c4c4 !important;
  color: white;
  width: 100%;
}
