.upload-tax__container {
  padding: 24px 32px;
}

.upload-tax__title {
  font-weight: 400;
  font-size: 22px;
  color: #000000;
  margin-bottom: 1em;
}

.upload-tax__subtitle {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
