.filter-user__container,
.filter-rating__container {
  margin-bottom: 24px;
}

.filter-user__item {
  padding: 8px 16px !important;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-right: 16px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #4c4c4c;
  cursor: pointer;
}

.sort-container {
  position: absolute;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  padding: 10px 16px !important;
  width: 138px;
  right: 0;
  top: 28px;
  z-index: 2;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: sort-animation;
  animation-duration: 0.5s;
}

@keyframes sort-animation {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.sort-text {
  font-weight: 500;
  font-size: 12px !important;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  cursor: pointer;
  margin-top: 2px;
}

.filter-rating__item {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;
  padding: 0 !important;
  width: 63px;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-right: 6px;
  cursor: pointer;
}

.filter-rating__item-text {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: #4c4c4c;
  height: 16px;
  line-height: 20px;
  margin-left: 1px;
}

.filter-user__active,
.filter-rating__active {
  background: #015352;
  color: white;
  border: 1px solid #015352;
}

.filter-rating__active > p {
  color: white;
}

.rating-text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-right: 14px;
}

.header-left {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
}

.sort-text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #4c4c4c;
  margin-left: 8px;
}

.image-container {
  width: 64px;
  height: 64px;
  margin-right: 16px;
}

.image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.review-user__name {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  margin-right: 8px;
}

.review-user__type {
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  margin-right: 16px;
  text-transform: "capitalize";
}

.review-user__time-mobile {
  display: none;
}

.review-user__time {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #808080;
}

.review-user__text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #4c4c4c;
  margin-bottom: 20px;
}

.like-number {
  font-weight: 500;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  margin: 0 4px 0 8px;
  color: #1a1a1a;
  margin-top: 4px;
}

.like-text {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: #4c4c4c;
  margin-left: 4px;
  margin-top: 4px;
}

.pagination {
  padding-top: 8px !important;
}

.rating-point__mobile-container {
  display: none !important;
}

.empty-state__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  margin-bottom: 4px;
  text-align: center;
  width: 100%;
}

@media (max-width: 414px) {
  .container {
    padding: 0 10px;
  }

  .filter-user__container {
    overflow: scroll;
    margin-bottom: 16px;
    margin-right: -16px;
  }

  .filter-user__item {
    font-size: 14px;
    line-height: 16px;
    padding: 6px 12px !important;
    white-space: nowrap;
    margin-right: 12px;
  }

  .rating-text {
    font-size: 14px;
    line-height: 16px;
    margin-right: 6px;
  }

  .filter-rating__item {
    width: 49px;
  }

  .filter-rating__item-text {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
  }

  .filter-rating__container {
    margin-bottom: 16px;
  }

  .header-left {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
  }

  .sort-text {
    font-size: 14px;
    line-height: 20px;
    margin-left: 6px;
  }

  .image-container {
    width: 40px;
    height: 40px;
  }

  .image {
    width: 40px;
    height: 40px;
  }

  .review-user__name {
    font-size: 14px;
    line-height: 16px;
    max-width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .review-user__time {
    display: none;
  }

  .review-user__time-mobile {
    margin-bottom: 8px;
    font-size: 10px;
    line-height: 16px;
    font-family: "Inter", sans-serif;
    color: #808080;
    display: flex;
  }

  .review-user__text {
    font-size: 14px;
    line-height: 16px;
    font-family: "Inter", sans-serif;
    color: #4c4c4c;
    margin-bottom: 12px;
  }

  .like-number {
    font-weight: 500;
    font-size: 14px;
    margin: 1px 4px;
  }

  .like-text {
    font-size: 14px;
    margin-top: 1px;
  }

  .pagination {
    padding-top: 2px !important;
  }
  .review-user__type {
    font-size: 10px;
    line-height: 16px;
  }

  .rating-text__mobile {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: "Inter", sans-serif;
    color: #1a1a1a;
    margin-left: 4px;
  }

  .rating-point__mobile-container {
    display: flex !important;
    position: relative;
    left: 0;
    align-items: center;
    bottom: 5px;
  }
}
