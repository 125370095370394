.input-id__title {
  font-weight: 400;
  font-size: 22px;
  color: #000000;
  margin-bottom: 1em;
}

.input-id__label {
    color: black;
    font-size: 14px;
}