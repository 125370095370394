.campaign-catalog__container {
  border: 1px solid #e2e8f0;
  border-radius: 14px;
  padding: 14px 10px;
}

.campaign-catalog__label {
  font-size: 14px;
  margin-bottom: 8px;
}

.campaign-catalog__input-wrapper {
  margin-bottom: 14px;
}

.campaign-catalog__label-price {
  font-size: 16px;
  font-weight: 600;
}
