.product-package__recommendation-container {
  .package-card {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 12px;
    border-bottom: 1px solid #e7e7e7;
    align-items: center;

    .package-text {
      text-align: center;
    }
    p {
      color: #1a1a1a;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0;
    }

    .text-product {
      color: #f17d84;
      cursor: pointer;
    }

    .icon-container {
      display: flex;
      justify-content: center;
    }
  }

  .table {
    margin-top: 32px;
    width: 100%;
    margin-bottom: 8px;

    .table-head {
      justify-content: space-between;
      width: 100%;
      padding: 8px 16px;
      border-radius: 4px;
      background: #ff8084;
      align-items: center;
      .table-head__item,
      .table-body__item {
        text-align: center;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        letter-spacing: 0;
        color: white;
      }
    }
  }
}

.popup-text__confirmation-package {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  color: #1a1a1a;
  text-align: center;

  margin-bottom: 32px;
}
