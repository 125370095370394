@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  z-index: 9999;
  opacity: 1;

  .select-all__checkbox {
    flex-direction: row-reverse;

    .chakra-checkbox__label {
      margin-right: 16px;
    }
  }

  .stock-popup {
    width: 45vw;
    height: 85vh;
    background: #f0f0f0;
    font-family: "Roboto", sans-serif;
    overflow-y: scroll;
    border-radius: 12px;
    padding: 32px;
    position: relative;
  }

  .button-container {
    position: sticky;
    bottom: -32px;
    background: #f0f0f0;
    width: 100% !important;
    height: 70px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .close-btn {
    position: relative;
    cursor: pointer;
    height: 30px;
    width: 30px;
  }

  .stock-popup-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .stock-popup-header__title {
      display: flex;
      align-items: center;
    }

    h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      text-transform: none !important;
      line-height: 24px;
    }
  }

  .stock-popup-searchbar {
    width: 100%;
    .search-container {
      background: #e5e5e5;
    }
  }

  .stock-popup-productLists {
    margin-top: 1.5em;
    position: relative;

    .stock-popup-products {
      .chakra-checkbox {
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid #cbcbcb;
      }
      .chakra-checkbox__control {
        border: 3px solid black;
        height: 18px;
        width: 18px;
      }

      .chakra-checkbox__label {
        margin-left: 20px;
      }

      .chakra-checkbox__input {
        margin-right: 10px;
      }

      .chakra-checkbox__label {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
      }
    }
  }

  .stock-popup-save {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 2.5em;
    background: #c4c4c4;
    border-radius: 35px;
    margin-top: 3.5em;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    position: sticky;
    z-index: 1000;
  }
}
