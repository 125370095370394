.generate-pdf {
  display: flex !important;
  justify-content: flex-end !important;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.product-info-header {
  background-color: rgb(240, 240, 240) !important;
}
