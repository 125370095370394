@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltip-container {
  margin-left: 5px;
  position: relative;
}

.tooltip-content-container {
  position: absolute;
  padding: 2px 6px;
  width: 200px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 4px 16px rgb(0 0 0 / 10%);
  display: flex;
  top: 1.5rem;
  right: -18px;
  align-items: flex-start;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  z-index: 999;
}

.fadeIn-animation {
  animation-name: fadeIn;
}

.hidden {
  opacity: 0;
  z-index: 0;
}

.tooltip-content-container::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 10%;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}

.tooltip-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #4c4c4c;
}
