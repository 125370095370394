.add-campaign__container {
  padding: 32px 24px;
}

.add-campaign__title {
  font-size: 22px;
  font-weight: 600;
}

.add-campaign__label {
  font-size: 14px;
}

.add-campaign-catalog__title {
  padding-left: 30px;
  padding-top: 30px;
  font-size: 18px;
}
