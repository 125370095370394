.input-promo__title {
  font-family: Roboto;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 8px;
  white-space: nowrap;
}

.input-promo__header-title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
}

.input-promo__title-wrapper {
  margin-bottom: 2em;
}
