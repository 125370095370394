.hide-button {
  align-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  border-radius: 4px;
}

.hide-button:hover {
  background: #e7e7e7;
}

.empty-state__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  margin-bottom: 4px;
  text-align: center;
  width: 100%;
}

.empty-state__text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  text-align: center;
  width: 100%;
}
