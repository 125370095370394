.feedback-article__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.feedback-article__wrapper {
  margin-bottom: 2em;
}

.feedback__article-table-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  padding: 20px;
}

.feedback__article-label {
  width: 180px;
  font-size: 16px;
  color: #1a1a1a;
}

.feedback__article-table-container tr {
  background: white;
}

.feedback__article-table-container th {
  background: #ff8084;
  color: white;
}
