.product-tagging__wrapper {
  padding-top: 24px;

  .product-tagging__container {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(229, 230, 234, 0.4) 0px 4px 8px 4px;
    padding: 16px;

    .header {
      border-bottom: 1px solid #efefef;
      padding-bottom: 16px;
      .title {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
      }

      .button {
        height: max-content !important;
        border-radius: 6px;
        width: max-content !important;
        padding: 4px 16px;
        background: #ff8084 !important;
      }
    }

    .input-container {
      height: 32px !important;
      background: #efefef !important;
      margin-right: 16px !important;
      border: none !important;
      font-size: 12px !important;
      color: #1a1a1a !important;
      width: 180px;
      padding-left: 32px !important;
    }

    .table {
      margin-top: 32px;
      width: 100%;

      .table-head,
      .table-body {
        justify-content: space-between;
        width: 100%;
        padding: 8px 16px;
        border-radius: 4px;
        background: #ff8084;
        align-items: center;
        .table-head__item,
        .table-body__item {
          text-align: center;
        }

        p {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          letter-spacing: 0;
          color: white;
        }
      }

      .table-body {
        background: none;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 18px;
        p {
          color: #1a1a1a;
          font-weight: 500;
        }

        .chakra-switch {
          margin: 0;
        }

        .text-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .text-product {
          color: #f17d84;
          cursor: pointer;
        }
      }
    }
  }
}

.popup-text__confirmation-package {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  color: #1a1a1a;
  text-align: center;

  margin-bottom: 32px;
}
