.image-container {
  width: 64px;
  height: 64px;
  margin-right: 16px;
}

.image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.review-user__name {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  margin-right: 8px;
}

.review-user__type {
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  margin-right: 16px;
  text-transform: "capitalize";
}

.review-user__time-mobile {
  display: none;
}

.review-user__time {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #808080;
}

.review-user__text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #4c4c4c;
  margin-bottom: 20px;
}

.slider-wrapper {
  position: relative;
  margin-top: 0.8rem;
  background: #f5f5f5;
  border-radius: 8px;
}

.image-wrapper {
  width: 100%;
  max-height: 60vh;
  object-fit: contain;
}

.image-review-container {
  height: 60vh;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-video {
  width: 100%;
  height: 265px;
  margin-top: 25%;
}
