.password-strength {
  position: static;
  border: 2px solid #029795;
  background: #029795;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 8px;
}

.password-weak {
  position: static;
  border: 2px solid #cccccc;
  background: #cccccc;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 8px;
}
