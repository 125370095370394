.list-community__container {
  padding: 32px 24px;
  background-color: #fffffd;
}

.react-bs-table-no-data {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

td.react-bs-table-no-data {
  font-weight: 500;
}

.list-community__title {
  font-size: 24px;
  font-weight: 600;
}

.list-community__button {
  background: #ff8084 !important;
  color: white;
  height: 36px;
}

.list-community__table-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  padding: 20px;
}

.list-community__table-container tr {
  background: white;
}

.list-community__table-container th {
  background: #ff8084;
  color: white;
}
