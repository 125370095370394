.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 85vh;
  align-items: center;
}

.error-section {
  text-align: center;

  h1 {
    font-size: 200px;
    font-weight: 900;
    margin: 0;
    line-height: 0.8;
    margin-top: -11px;
    color: grey;
    opacity: 0.3;
  }

  h2 {
    font-weight: 500;
    letter-spacing: 3px;
    margin: 40px 0;
  }

  h3 {
    font-size: 200px;
    font-weight: 900;
    margin: 0;
    line-height: 0.8;
    margin-top: -11px;
    color: grey;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 10em;
    }
  }

  @media (min-width: 769px) {
    h3 {
      font-size: 200px;
    }
  }
}

.error-section-subtitle {
  margin: 1em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #1a1a1a;
}

.error-section-title {
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 100px;
    text-align: center;
    color: #3e7cc0;
  }
}
