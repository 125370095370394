.data-campaign__status {
  border-radius: 35px;
  border: 1px solid transparent;
  padding: 0 6px;
  color: white;
  font-weight: 500;
}

.data-campaign__bullet {
  background-color: #e8f9fd;
  border-radius: 35px;
  border: 1px solid transparent;
  padding: 0 6px;
  color: #4c4c4c;
}

.data-campaign__url {
  border-radius: 8px;
  background-color: #f8f8f9;
  padding: 0 8px;
}

.campaign-accordion__container {
  background-color: #f8f8f9;
  margin: 1em auto;

  .chakra-accordion__button {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .expand-campaign-accordion__container {
    background-color: white;
  }
}
