/* Table */

.list-promo__container {
    padding: 32px 24px;
  }
  
  .list-promo__title {
    font-size: 24px;
    font-weight: 600;
  }
  
  .list-promo__btn-create {
    background: #ff8084 !important;
    color: white;
    height: 36px;
  }
  
  .list-promo__content-container {
    background: white;
    border-radius: 8px;
    box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
    width: 100%;
    height: max-content;
    margin-top: 32px;
    padding: 20px;
  }
  
  .list-promo__table-container tr {
    background: white;
  }
  
  .list-promo__table-container th {
    background: #ff8084;
    color: white;
  }
  
  /* Tab */
  
  .promo-list__section-tab__container {
    margin-bottom: 24px;
  }
  
  .promo-list__section-tab__item {
    width: max-content;
    padding: 8px 24px;
    background: white;
    border-radius: 8px;
    margin-right: 12px;
    box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
    cursor: pointer;
  }
  
  .promo-list__section-tab__container .tab-active {
    background: #ff8084;
    color: white;
  }
  
  .promo-list__section-tab__container-payment {
    margin-top: 24px;
    margin-bottom: 0;
    width: 100%;
  }
  
  /* Date Filter */
  
  .promo-list__datepicker__container {
    align-items: center;
  }
  
  .promo-list__datepicker__container .react-datepicker-wrapper {
    width: 150px;
    margin: 0 8px;
    cursor: pointer;
  }
  
  .promo-list__datepicker__container .react-datepicker-wrapper input {
    width: 100%;
    text-align: center;
    height: 40px;
  }
  
  .promo-list__datepicker-text {
    font-size: 16px;
    color: black;
    letter-spacing: 0;
    width: max-content;
    margin-right: 8px;
  }
  