.campaign-list__datepicker__container {
  align-items: center;
  width: 100%;
}

.campaign-list__datepicker__container .react-datepicker-wrapper {
  width: 100%;
  margin: 0 8px;
  cursor: pointer;
}

.campaign-list__datepicker__container .react-datepicker-wrapper input {
  width: 100%;
  text-align: center;
  height: 40px;
}

.campaign-list__datepicker-text {
  font-size: 16px;
  color: black;
  letter-spacing: 0;
  width: max-content;
  margin-right: 8px;
}
