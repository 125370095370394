
.add-category {
    .row {
      .col {
        display: flex;
        margin-bottom: 15px;
        .box-input-file-full {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          .upload {
            position: absolute;
            width: 70px;
            left: 0;
            right: 0;
            opacity: 0;
          }
        }
        .box-input-file {
          width: 72px;
          height: 72px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          .upload {
            position: absolute;
            width: 70px;
            left: 0;
            right: 0;
            opacity: 0;
          }
        }
      }
    }
}

.card-title {
    font-size: 24px;
    font-weight: bold;
    color: black;
}

.card-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    color: black
}


.card-value {
    font-size: 16px;
    margin-left: 16px;
    margin-bottom: 8px;
    color: black
}

.button-main {
    background-color: #FF8084;
    color: white;
}

.mb-8 {
    margin-bottom: 8px;
}

.groupcategory-title {
    font-size: 16px;
    font-weight: 700;
    color: black
}

.groupcategory-row {
    padding: 16px 0px;
    border-bottom: 3px solid rgba(0,0,0,0.05)
}

.category-title {
    font-size: 14px;
    font-weight: 700;
    color: black
}

.category-row {
    padding: 16px 0px;
    border-bottom: 3px solid rgba(0,0,0,0.05)
}

.subcategory-title {
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
    color: black
}

.subcategory-row {
    padding: 16px 0px;
    border-bottom: 3px solid rgba(0,0,0,0.05)
}

.search-bar {
    margin-right: 8px;
    width: 30%;
}

.sort-by-selector {
    margin-right: 8px;
    width: 100%;
}

.pb-16 {
    padding-bottom: 16px;
}

.pv-8 {
    padding: 8px 0px;
}

.pv-16 {
    padding: 16px 0px;
}