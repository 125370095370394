.rdw-editor-main {
  min-height: 30vh;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.select-color {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.select-color__button {
  height: 36px;
  background: #ff8084;
  border-radius: 6px;
  color: white;
  padding: 4px 8px;
}

.color-selected {
  height: 18px;
  width: 18px;
  border-radius: 4px;
  margin: 0px 8px 0 16px;
  border: 1px solid grey;
  cursor: pointer;
}

.color-selected__text {
  font-size: 16px;
  letter-spacing: 0;
  color: #1a1a1a;
}
.modal-picker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-pallete {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.sketch-container {
  flex-direction: column;
  padding: 16px;
  align-items: flex-end;
  background: white;
  position: relative;
  display: flex;
  border-radius: 8px;
}

.pallete-button__save {
  background: #ff8084;
  color: white;
  padding: 8px 32px;
  border-radius: 8px;
  margin-top: 32px;
  float: right;
  font-size: 16px;
  height: 40px;
}

.close-pallete {
  color: black;
  float: right;
  margin-bottom: 16px;
  cursor: pointer;
}

li.public-DraftStyleDefault-orderedListItem {
  display: block;
  margin: 1px 0;
}

li.public-DraftStyleDefault-unorderedListItem {
  display: list-item;
  list-style-type: square;
  margin: 1px 0;
}

/* How to use */

.browse-file-btn input[type="file"] {
  display: none;
}

.browse-file-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #319795;
  color: #ffffff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bolder;
  font-style: normal;
  width: fit-content;
  padding: 6px 10px;
  margin: 0.5rem 0;
  margin-bottom: 1rem;
  cursor: pointer;
}

.container-variant {
  max-height: 605px;
  overflow: auto;
}
