.container {
  padding-top: 24px;
  margin-bottom: 24px;
}

.container-left {
  margin-right: 45px;
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  margin-bottom: 24px;
}

.number-bold {
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
}

.number-grey {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  color: #808080;
  margin-left: 1px;
}

.total-review__text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #4c4c4c;
}

.box-outside {
  background: #cccccc;
  width: 144px;
  border-radius: 8px;
  position: relative;
  height: 8px;
  margin-right: 4px;
  padding: 0 !important;
}

.box-inside {
  background: #015352;
  border-radius: 8px;
  position: absolute;
  top: 0;
  height: 8px;
}

.star-text {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
  margin: 0 15px 0 4px;
  width: 7px;
}

.total-review__text-right {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #4c4c4c;
}
