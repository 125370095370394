@import "~bootstrap-scss/bootstrap.scss";
@import "theme/variables";
@import "theme/admin_style";
@import "theme/rtl";
@import "flag-icon.scss";
@import "themify.scss";
@import "icoicon/icons";
@import "font-awesome.scss";

.pagination-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 32px !important;

  .pagination {
    list-style-type: none;
    margin: 0;
    padding-inline-start: 0;
    display: flex;

    .pagination__item,
    .pagination__item-link {
      outline: none;
    }

    .disabled {
      display: none !important;
    }

    .active {
      .pagination__item-link {
        background-color: #f15c5f;
        color: #ffffff;
        outline: none;
      }
    }

    .next,
    .pagination__item,
    .previous {
      cursor: pointer;
    }

    .pagination__item {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .pagination__item-link,
    .next,
    .previous {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 50%;
    }
  }
}
