.admin__list-admin-container {
  padding: 32px 24px;
}

.admin__list-admin-title {
  font-size: 24px;
  font-weight: 600;
}

.admin__list-admin-button-add {
  background: #ff8084 !important;
  color: white;
  height: 36px;
}

.admin__list-admin-table-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  padding: 20px;
  margin-top: 1em;
}
.admin__list-admin-table-container tr {
  background: white;
}

.admin__list-admin-table-container th {
  background: #ff8084;
  color: white;
}
