.reseller-discount__container {
  padding: 32px 24px;
}

.reseller-discount__title {
  font-size: 24px;
  font-weight: 600;
}

.admin__list-admin-button-add {
  background: #ff8084 !important;
  color: white;
  height: 36px;
}

.reseller-discount__table-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  padding: 20px;
  margin-top: 1em;
}

.reseller-discount__table-title {
  font-size: 20px;
  font-weight: 400;
}

.reseller-discount__table-container tr {
  background: white;
}

.reseller-discount__table-container th {
  background: #ff8084;
  color: white;
}
