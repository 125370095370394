.help-center__feedback-container {
  padding: 32px 24px;
}

.help-center__feedback-title {
  font-size: 24px;
  font-weight: 600;
}

.help-center__feedback-content-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  margin-top: 32px;
  padding: 20px;
}

.help-center__feedback-content-title {
  font-size: 18px;
  font-weight: bold;
  color: black;
  max-width: 300px;
}
