.redemption-list__section-tab-container {
  margin-bottom: 24px;
}

.redemption-list__section-tab__item {
  width: max-content;
  padding: 8px 24px;
  background: white;
  border-radius: 8px;
  margin-right: 12px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  cursor: pointer;
}

.redemption-list__section-tab-container .tab-active {
  background: #ff8084;
  color: white;
}

.redemption-list__section-tab__container-payment {
  margin-top: 24px;
  margin-bottom: 0;
  width: 100%;
}
