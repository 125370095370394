.role-based-table__container .MUpkg {
  background-color: #ff8084;
}

.role-based-table__container .iwLUQD {
  color: white;
}

.role-based-table__container {
  margin-bottom: 1.5em;
}
