.redemption-list__container {
  padding: 32px 24px;
}

.redemption-list__title {
  font-size: 24px;
  font-weight: 600;
}

.redemption-list__content-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  margin-top: 32px;
  padding: 20px;
}

.redemption-list__content-filter {
  font-size: 16px;
  color: black;
  letter-spacing: 0;
  width: 50%;
  margin-right: 8px;
}

.redemption-list__content-filter-wrapper {
  justify-content: space-between;
  margin: 16px 0;
}

.redemption-list__content-filter-wrapper .basic-single {
  width: 145%;
}

.list-redemption__table-container tr {
  background: white;
}

.list-redemption__table-container th {
  background: #ff8084;
  color: white;
}
