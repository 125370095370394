.list-testimony__container,
.main-testimony__container {
  font-family: "Inter", sans-serif;
  background: white;
  width: 100%;
  margin-top: 100px;
  padding: 16px;
  margin-bottom: 32px;

  border-radius: 8px;
  box-shadow: rgba(229, 230, 234, 0.4) 0px 4px 8px 4px;

  .skeleton-container {
    border-bottom: 1px solid #e7e7e7;
  }
  .header {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;

    .title {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
    }

    .button {
      height: max-content !important;
      border-radius: 6px;
      width: max-content !important;
      padding: 4px 16px;
      background: #ff8084 !important;
    }
  }

  .testimony-card__container {
    margin: 32px 0;
  }
  .testimony-card {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 12px;
    border-bottom: 1px solid #e7e7e7;

    align-items: center;

    .image {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
    }

    .icon-container {
      display: flex;
      align-items: center;
    }

    .chakra-switch__track[data-checked] {
      background: #5fc6a4;
      outline: none;
    }

    .name {
      font-size: 14px;
      font-weight: 600;
      color: #1a1a1a;
      letter-spacing: 0;
    }

    .profile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 16px;
      width: 160px;
    }
    .desc {
      font-size: 14px;
      color: #1a1a1a;
      font-weight: 500;
      width: 35%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      letter-spacing: 0;
      margin-right: 16px;
    }

    .city {
      font-size: 14px;
      color: #1a1a1a;
      font-weight: 500;
      letter-spacing: 0;
      margin: 0 16px;
      width: 10%;
    }
    .status {
      font-size: 12px;
      color: grey;
      font-weight: 500;
      letter-spacing: 0;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  .chakra-checkbox__control[data-checked] {
    background: #62d9b9 !important;
    outline: none !important;
    border-color: #62d9b9;
  }
}

.main-testimony__container {
  margin-top: 0;
  width: 100%;

  .avatar-image__main {
    width: 72px;
    border-radius: 50%;
    object-fit: cover;
    height: 72px;
  }

  .chakra-switch__track[data-checked] {
    background: #5fc6a4;
    outline: none;
  }

  .main-testimony__card {
    box-shadow: rgba(229, 230, 234, 0.4) 0px 4px 8px 4px;
    border-radius: 8px;
    padding: 16px;
  }

  .name-text {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
  }

  .status-text,
  .city-text {
    font-size: 14px;
    letter-spacing: 0;
    color: #4c4c4c;
  }

  .desc-text {
    font-size: 14px;
    color: #4c4c4c;
    letter-spacing: 0;
    margin-top: 16px;
  }

  .city-text {
    margin-left: 4px;
  }
}
