.invoice-container {
  padding: 1rem 3rem;
}

.invoice-header {
  align-items: center;
}

.invoice-title {
  color: black;
  padding: 4px 0px;
}

.invoice-info-section {
  margin-bottom: 8px;
}

.invoice-empty-space {
  height: 24px;
}

.invoice-product-name {
  width: 230px !important;
  word-wrap: break-word;
}

.invoice-info-title {
  letter-spacing: normal !important;
  text-transform: none !important;
  line-height: 1.4 !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
  border-color: black !important;
  color: black !important;
  font-weight: bold !important;
  padding: 4px 0px;
}

.invoice-info-desc {
  color: black;
  line-height: 1.4 !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: normal !important;
}

.invoice-divider {
  width: 100%;
  height: 2px;
  margin: 12px 0;
  background-color: black;
}

.invoice-table td,
.invoice-table th {
  padding: 8px !important;
  color: black !important;
  background-color: white !important;
  line-height: 1.4 !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: normal !important;
}
