.stock-list-container {
  width: 100;

  .stock-list-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 32px 16px;
    align-items: center;

    .stock-list-button {
      width: 10em;
      height: 2.5em;
      background-color: #c4c4c4;
      border-radius: 35px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 36px;
    }
  }

  .stock-list-body {
    background: white;
    padding: 16px;
    border-radius: 8px;

    .stock-list-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 16px 32px 16px;
      align-items: center;

      .dc-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 36px;
        text-transform: none;
      }

      .product-search {
        width: 25%;
        text-align: center;
        margin-right: 1.5em;
        background-color: #f8f8f9;
      }
    }

    .variant-capsule {
      background-color: #ff8084;
      color: white;
      font-weight: bold;
      text-transform: capitalize;
    }

    .accordian-container {
      background-color: #f8f8f9;
      width: 95%;
      margin: auto;

      .expand-accordian-container {
        background-color: white;

        .input-wrapper {
          display: flex;
          flex-direction: column;

          .input-label {
            font-size: 12px;
            line-height: 23px;
            text-align: left;
            margin-right: 0.5em;
            margin-bottom: 8px;
          }
          input {
            height: 30px;
            width: 100px;
            border: 1px solid #afacac;
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }
  }
}
