.reseller-discount__add-modal {
  padding: 20px 32px;
}

.reseller-discount__add-modal-title {
  font-weight: 400;
  font-size: 22px;
}

.reseller-discount__add-modal-wrapper {
  margin-top: 1em;
  margin-bottom: 1em;
}

.reseller-discount__add-modal-subtitle {
  font-weight: 400;
  font-size: 14px;
}
