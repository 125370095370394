.list-warehouse__container {
  width: 100%;

  .list-warehouse__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 32px 16px;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 36px;
    }

    .button-add__warehouse {
      background: #ff8084;
      color: white;
      height: 36px;
    }
  }

  .list-warehouse__body {
    background: white;
    padding: 16px;
    border-radius: 8px;

    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 32px;
    }

    .list-warehouse__wrapper {
      display: flex;
      flex-direction: column;

      .list-warehouse__item {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        padding: 8px;
        border-bottom: 2px solid #e2e0e0;

        .list-warehouse__item-right {
          display: flex;
        }
      }
    }
  }
}
