@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.section-banner__container {
  padding-left: 3em;
  font-family: "Inter", sans-serif;
  margin: 0;
}

.left-section {
  margin-top: 108px;
  padding: 0 16px 0 0;
}

.left-section__title {
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  color: #808080;
  margin-bottom: 16px;
  letter-spacing: 0;
}

.left-section__main-title {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 16px;
  max-width: 440px;
  letter-spacing: 0;
}

.left-section__content {
  max-width: 440px;
}

.left-section__content,
.left-section__button-copy {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #1a1a1a;
  margin-bottom: 36px;
  letter-spacing: 0;
}

.left-section__button-copy {
  margin-bottom: 12px;
}

.input-group {
  width: 100% !important;
  margin-bottom: 16px;
}

.input {
  height: 48px !important;
}

.button-container {
  height: 48px !important;
  width: 80px !important;
}

.button {
  height: 100% !important;
  width: 80px !important;
  background: #158474 !important;
  color: white !important;
}

.left-section__contact-us {
  font-size: 12px;
  font-weight: 400;
  color: #4c4c4c;
  display: flex;
  line-height: 20px;
  letter-spacing: 0;
}
.contact-us {
  font-size: 12px;
  line-height: 20px;
  margin-left: 4px;
  text-decoration: underline;
  letter-spacing: 0;
  cursor: pointer;
}

.image-container {
  aspect-ratio: 3/2;
  object-fit: cover;
  width: 100%;
}

.right-section {
  padding: 0;
}

.mobile-section-banner__container {
    margin: 0;
    padding: 0;
}

.mobile-image-container {
    aspect-ratio: 1/1;
    object-fit: cover;
}

.mobile-left-section {
    padding: 0 16px;
    margin-top: 24px;
}

.mobile-left-section__title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    font-weight: 600;
    color: #808080;
    margin-bottom: 16px;
    letter-spacing: 0;
}

.mobile-left-section__main-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
    font-weight: 700;
    margin-bottom: 16px;
    letter-spacing: 0;
}

.mobile-left-section__content,
.mobile-left-section__button-copy {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
    color: #1a1a1a;
}

.mobile-left-section__button-copy {
    color: #4c4c4c;
    margin-bottom: 12px;
}

.mobile-input-group,
.mobile-input,
.mobile-button-container {
    height: 40px !important;
}

.mobile-button-container {
    margin-bottom: 12px;
}

.mobile-left-section__contact-us,
.mobile-contact-us {
    font-size: 10px;
    line-height: 16px;
    display: inline-block;
}

.mobile-contact-us {
    margin-left: 4px;
    text-decoration: underline;
}

.mobile-left-section__contact-us {
    margin-bottom: 26px;
}

.mobile-button-container {
    width: 80px !important;
}