.list-suggestion__container {
  padding: 32px 24px;
  background-color: #fffffd;
}

.list-suggestion__title {
  font-size: 24px;
  font-weight: 600;
}

.list-suggestion__button {
  background: #ff8084 !important;
  color: white;
  height: 36px;
}

.list-suggestion__table-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  padding: 20px;
}

.list-suggestion__table-container tr {
  background: white;
}

.list-suggestion__table-container th {
  background: #ff8084;
  color: white;
}

.list-suggestion__datepicker__container {
  align-items: center;
  justify-content: end;
}

.list-suggestion__datepicker__container .react-datepicker-wrapper {
  width: 150px;
  margin: 0 8px;
  cursor: pointer;
}

.list-suggestion__datepicker__container .react-datepicker-wrapper input {
  width: 100%;
  text-align: center;
  height: 40px;
}

.list-suggestion__datepicker-text {
  font-size: 16px;
  color: black;
  letter-spacing: 0;
  width: max-content;
  margin-right: 8px;
}
