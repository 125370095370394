tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05);
}
table {
  width: 100%;
  border-spacing: 0px;
}
th,
td {
  padding: 8px;
}

.text-align-right {
  text-align: right;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.hidden-tr {
  visibility: hidden;
  opacity: 0;
}
