.product-list__container {
  position: fixed;
  inset: 0;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  .overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .main {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }

  .main::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .main {
    background: white;
    width: 600px;
    position: relative;
    border-radius: 8px;
    padding: 0 16px 16px;
    height: 50vh;
    overflow: auto;

    .header {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      border-bottom: 1px solid #eaeaea;
      position: sticky;
      top: 0;
      background: white;

      .header-text {
        font-size: 16px;
        font-family: 600;
        color: #1a1a1a;
        letter-spacing: 0;
      }
    }

    .product {
      display: flex;
      margin-top: 16px;

      .number,
      .text {
        font-size: 14px;
        color: #4c4c4c;
        letter-spacing: 0;
        margin-right: 8px;
      }

      .text {
        margin: 0;
      }
    }
  }
}
